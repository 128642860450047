<template>
  <div class="header-bar">
    <Menubar class="header-bar_menu">
      <template #start>
        <img
          src="assets/images/mckesson-compile-blanc.svg"
          alt="McKesson Compile Blanc logo"
        />
      </template>

      <template #end>
        <div class="flex align-items-center gap-2">
          <Dropdown
            :options="ddUserItems"
            option-label="name"
            :placeholder="appStore.user.name"
            class="md:w-10rem"
            @change="onChangeHandler($event)"
          />
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script lang="ts" setup>
  import type { IDropdownChangeEvent, IUserDropDown } from '~/stores/types/app'

  // Store
  const appStore = useAppStore()
  // Runtime config composable to refer environment variables
  const config = useRuntimeConfig()

  // Properties
  const ddUserItems: IUserDropDown[] = [
    {
      name: 'Change Password',
      id: 'change-password',
      path: '/accounts/password/change/',
    },
    { name: 'Sign Out', id: 'sign-out', path: '/accounts/logout/' },
  ]

  // Methods
  const onChangeHandler = async (selectedItem: IDropdownChangeEvent) => {
    const path = HelperMethods.urlWithRedirect(
      config.public.accountsURL + selectedItem.value.path,
    )

    await navigateTo(path, { external: true })
  }
</script>
<style lang="scss" scoped>
  @import 'assets/scss/_color-palette.scss';

  .header-bar {
    &_menu {
      background-color: $dark-blue;
      height: 3.875rem; //62px
      border-bottom: 1px solid $border-gray;
    }
  }
</style>
