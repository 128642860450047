<template>
  <div class="default-layout">
    <LoaderDialog v-if="appStore.isLoading" />
    <div class="default-layout_container">
      <HeaderBar />
      <div class="flex">
        <NavBar />
        <div class="w-full default-layout_router">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  const appStore = useAppStore()
</script>
<style scoped lang="scss">
  @import 'assets/scss/_color-palette.scss';
  .default-layout {
    font-family: 'Libre Franklin';
    &_container {
      background-color: $midnight-blue;
    }
  }
</style>
