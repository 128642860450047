<template>
  <div class="nav-bar">
    <div
      v-for="navItem in navItems"
      :key="navItem.label"
      class="nav-list"
      @click="onItemClick(navItem)"
    >
      <div class="nav-wrapper">
        <div
          class="nav-item flex flex-column"
          :class="selectedItem === navItem.label ? 'active-item' : ''"
        >
          <div
            :class="[navItem.icon, navItem.icon ? 'pb-2' : '']"
            class="flex align-items-center justify-content-center"
          />
          <div
            class="flex align-items-center justify-content-center text-center"
          >
            {{ navItem.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  // Store
  const datasetsStore = useDatasetsStore()
  const jobsStore = useJobsStore()
  const appStore = useAppStore()

  // Runtime config composable to refer environment variables
  const config = useRuntimeConfig()

  // Properties
  const navItems = ref([
    {
      label: 'Console Home',
      icon: '',
      to: '',
    },
    {
      label: 'Home',
      icon: 'pi pi-home',
      to: '/',
    },
    {
      label: 'Dataset',
      icon: 'pi pi-table',
      to: '/dataset/',
    },
    {
      label: 'Jobs',
      icon: 'pi pi-inbox',
      to: '/jobs/',
    },
    {
      label: 'Admin',
      icon: 'pi pi-th-large',
      to: '',
    },
  ])
  const selectedItem: Ref<string> = ref('Home')

  // Hooks
  onMounted(() => {
    setActiveRoute()
  })

  // Methods
  const onItemClick = async (value: {
    label: string
    icon: string
    to: string
  }) => {
    switch (value.label) {
      case 'Admin':
      case 'Console Home': {
        const path =
          value.label === 'Admin'
            ? (config.public.adminURL as string) + '/kitchen/blanc/'
            : config.public.consoleAdmin
        await navigateTo(path, {
          external: true,
          open: {
            target: '_blank',
          },
        })
        return
      }
      case 'Dataset':
        datasetsStore.setActiveDatasetView(DatasetView.Dataset)
        if (datasetsStore.datasets.length <= 0) {
          await datasetsStore.fetchDatasets()
        }
        break
      case 'Jobs':
        jobsStore.setActiveJobsView(JobsView.Jobs)
        if (jobsStore.jobs.length === 0) {
          await jobsStore.fetchJobs()
        }
        break
      default:
        appStore.setActiveHomeView(HomeView.Dashboard)
        break
    }
    selectedItem.value = value.label
    await navigateTo(value.to)
  }

  // To set the active nav item for the current route
  const setActiveRoute = () => {
    const router = useRouter()
    const currentRoute = navItems.value.find(
      (item) =>
        item.to === router.currentRoute.value.path ||
        (item.to !== '' && item.to + '/' === router.currentRoute.value.path),
    )
    selectedItem.value = currentRoute?.label as string
  }
</script>

<style lang="scss" scoped>
  @import 'assets/scss/_color-palette.scss';
  .nav-bar {
    background-color: $midnight-blue;
    height: calc(100vh - 3.875rem);
    border: none;
    border-right: 1px solid $border-gray;
  }
  .nav-wrapper {
    margin: 0.5rem !important;
  }
  .nav-item {
    color: $white;
    padding-block: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;

    &:hover {
      background: $white;
      color: $dark-blue;
    }
  }
  .active-item {
    background: $white;
    color: $dark-blue;
  }
  .nav-bar .nav-list:nth-child(1) {
    border-bottom: 1px solid $border-gray;
    .nav-item {
      padding: 0.2rem;
      margin: 0.45rem;
    }
    .nav-wrapper {
      margin: 0 !important;
    }
  }
</style>
